import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from '@reach/router';
import { getPathWithoutLocale } from '@helpers/url'
import LocaleContext from '@context/LocaleContext';
import Link from '@utility/Link';
import cn from 'classnames';
import styles from './styles';

const CategoryFilter = ({ heading, categories }) => {
  const location = useLocation();
  const currentSlug = getPathWithoutLocale(location.pathname);

  return (
    <div className={styles.hero}>
      <div className="wrapper">
        <div className="row">
          <div className="col-12 md:col-3 md:offset-1">
            <h1 className={`${styles.title} mb-6 md:mb-0`}>{heading}</h1>
          </div>
          <div className="col-12 md:col-7 md:offset-1 lg:col-8 lg:ml-0">
            <div className="flex items-end h-full">
              <ul className="flex items-center flex-wrap mb-5">
                <li className={cn( styles.item, {
                  [styles.active]: currentSlug === '/tattoos'
                })}>
                  <Link to='/tattoos'>
                    <LocaleContext.Consumer>
                      {locale => (
                        locale.code === 'jp' ? '全て': 'All'
                      )}
                    </LocaleContext.Consumer>
                  </Link>
                </li>
                {categories.map(category => {
                  return (
                    <li key={category.node.id} className={cn( styles.item, {
                      [styles.active]: currentSlug === `/${category.node.slug.current}`
                    })}>
                      <Link to={`/${category.node.slug.current}`}>
                        {category.node.title}
                      </Link>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CategoryFilter.propTypes = {
  heading: PropTypes.string.isRequired,
  categories: PropTypes.array.isRequired,
};

export default CategoryFilter;
