import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import LocaleContext from '@context/LocaleContext';
import CategoryFilter from '@components/CategoryFilter';
import TattooList from '@components/TattooList';
import Layout from '@components/Layout';
import SEO from '@components/seo';
import localize from '@components/localize';

export const query = graphql`
  query($id: String) {
    allSanityTattoo(
      filter: { category: { id: { eq: $id } } }
      sort: {fields: date, order: DESC }
    ) {
      edges {
        node {
          ...tattooPageFragment
        }
      }
    }
    allSanityTattooCategory(
      sort: {fields: orderPriority, order: DESC }
    ) {
      edges {
        node {
          ...tattooCategoryFragment
        }
      }
    }
    sanityTattooPage {
      title {
        en
        jp
      }
      description {
        en
        jp
      }
      metaImage {
        asset {
          _id
          url
        }
      }
    }
  }
`;

const TattooCategoryTemplate = ({
  data: { allSanityTattoo, allSanityTattooCategory, sanityTattooPage },
  pageContext: { title }
}) => {

  return (
    <LocaleContext.Consumer>
    {locale => (
      <Layout>
        <SEO title={title} description={sanityTattooPage.description[locale.code]} image={sanityTattooPage.metaImage?.asset.url} />
        <div className="pt-15 md:pt-20 bg-black"></div>
        <CategoryFilter heading={locale === 'jp' ? sanityTattooPage.title.jp : sanityTattooPage.title.en } categories={allSanityTattooCategory.edges} />
        <hr className="border-grey-dark" />
        <div className="bg-black pt-12 lg:pt-24 pb-10 md:pb-16">
          <TattooList tattoos={allSanityTattoo.edges}/>
        </div>
        <hr className="border-grey-dark" />
      </Layout>
    )}
  </LocaleContext.Consumer>
  );
};

TattooCategoryTemplate.propTypes = {
  data: PropTypes.shape({
    allSanityTattoo: PropTypes.shape({
      edges: PropTypes.array,
    }),
    allSanityTattooCategory: PropTypes.shape({
      edges: PropTypes.array,
    }),
    sanityTattooPage: PropTypes.shape({
      description: PropTypes.object,
      metaImage: PropTypes.object,
    }),
  })
};

TattooCategoryTemplate.defaultProps = {
  data: {
    allSanityTattoo: {},
    allSanityTattooCategory: {},
    sanityTattooPage: {},
  },
};

export default localize(TattooCategoryTemplate);
