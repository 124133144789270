import React from 'react';
import PropTypes from 'prop-types';
import GalleryCard from '@components/GalleryCard';
import InViewport from '@components/InViewport';
import styles from './styles';

const TattooList = ({ category, tattoos }) => {

  return (
    <div className={styles.container}>
      <section className="text-white">
        <div className="wrapper">
          <div className="row">
            {category && category.node && (
              <InViewport className={`${styles.title} col-6 md:col-4`}>
                {category.node.title}
              </InViewport>
            )}
            {tattoos.map(tattoo => {
              return (
                <div key={tattoo.node.id} className="col-6 md:col-4 text-white">
                  <GalleryCard data={tattoo.node}/>
                </div>
              )
            })}
          </div>
        </div>
      </section>
    </div>
  );
};

TattooList.propTypes = {
  tattoos: PropTypes.array.isRequired,
  category: PropTypes.shape({
    title: PropTypes.string
  })
};

TattooList.defaultProps = {
  category: {
    title: '',
  }
};

export default TattooList;
